export const deviceSelectionIcon = (typeDevice) => {
  const typeDevicetoString = typeDevice.toLowerCase(); // Convertir el tipo de dispositivo a minúsculas
  switch (typeDevicetoString) {
    case 'aire acondicionado':
      return 'AirConditioning';
    case 'luces':
      return 'Focus';
    case 'deshumidificador':
      return 'Humidity';
    case 'ventilador':
      return 'Caloventor';
    case 'ventilador in':
      return 'VentilationIN';
    case 'ventilador out':
      return 'VentilationOUT';
    default:
      return 'Default';
  }
};
