import PropTypes from 'prop-types';
import React from 'react';
import Alerta from '../../assets/img/Alerta.png';
import CambiodePerfil from '../../assets/img/CambiodePerfil.png';
import ConnectionGreen from '../../assets/img/Connection-green.png';
import ConnectionRed from '../../assets/img/Connection-red.png';
import ConnectionYellow from '../../assets/img/Connection-yellow.png';
import Connection from '../../assets/img/Connection.png';
import Cosecha from '../../assets/img/Cosecha.png';
import Default from '../../assets/img/Default.png';
import IngresoManual from '../../assets/img/IngresoManual.png';
import Recomendacion from '../../assets/img/Recomendacion.png';
import Reporte from '../../assets/img/Reporte.png';
import Add from '../../assets/img/add.png';
import AirConditioning from '../../assets/img/aire.png';
import ArrowLeft from '../../assets/img/back.png';
import Bell from '../../assets/img/bell.png';
import Caloventor from '../../assets/img/caloventor.png';
import DisableBlack from '../../assets/img/disabled-black.png';
import DisableWhite from '../../assets/img/disabled-white.png';
import Home from '../../assets/img/home.png';
import Establishments from '../../assets/img/iconoEstablecimiento.png';
import Hemp from '../../assets/img/iconoHemp.png';
import Plantacion from '../../assets/img/iconoPlantacion.png';
import Humidity from '../../assets/img/lluvia.png';
import User from '../../assets/img/logoUser.png';
import Focus from '../../assets/img/luz.png';
import Refresh from '../../assets/img/refresh.png';
import Settings from '../../assets/img/settings.png';
import Delete from '../../assets/img/trash.png';
import VentilationIN from '../../assets/img/VentilacionIN.png';
import VentilationOUT from '../../assets/img/VentilacionOUT.png';

const iconImages = {
  Reporte,
  Cosecha,
  Alerta,
  CambiodePerfil,
  Connection,
  ConnectionYellow,
  ConnectionGreen,
  ConnectionRed,
  IngresoManual,
  Recomendacion,
  Bell,
  DisableWhite,
  DisableBlack,
  User,
  Hemp,
  Establishments,
  Plantacion,
  Home,
  Delete,
  Add,
  Settings,
  ArrowLeft,
  Caloventor,
  AirConditioning,
  Focus,
  Humidity,
  Default,
  Refresh,
  VentilationIN,
  VentilationOUT
};
const Icon = ({ name, size = 35 }) => (<img src={iconImages[`${name}`]} width={size} alt={name} />);
Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export default Icon;
