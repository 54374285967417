/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import CardDesktop from '../../components/card-home-desktop';
import Modulos from '../../components/card-info/modulos';
import Carousel from '../../components/carrusel';
import Spinner from '../../components/spinner';
import withAuth from '../../hoc';
import { cleanEstablishment } from '../../redux/actions/establishment';
import { getAllEstablishmentsPanel, getAllEstablishmentsPanelNotLoading } from '../../redux/actions/home';
import CardInfoHeader from './card-info-header';

const Home = () => {
  const dispatch = useDispatch();

  const { establishments } = useSelector((state) => state.establishmentReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [executeListener, setExecuteListener] = useState(true);

  const getData = () => {
    dispatch(getAllEstablishmentsPanel());
  };
  useEffect(() => {
    getData();
    const intervalId = setInterval(
      () => (dispatch(getAllEstablishmentsPanelNotLoading())),
      30000
    );
    return () => {
      clearInterval(intervalId);
      dispatch(cleanEstablishment());
    };
  }, []);

  console.log(executeListener);

  return establishments ? (
    <div className={isMobile ? 'container-principal' : 'home-desktop'}>
      {isMobile
        ? (
          <div className="container-carousel">
            <Carousel countToShow={establishments.length}>
              {establishments?.map((establisment) => (
                <div className="container-card" key={establisment._id}>
                  <div className="card-header">
                    <CardInfoHeader
                      title={establisment.nombre}
                      latitud={establisment.latitud}
                      longitud={establisment.longitud}
                    />
                  </div>
                  <Modulos
                    modules={establisment.modulos}
                    wifi={establisment.minUltimoValor}
                    idEstablishment={establisment._id}
                    listenEstablishment={() => {
                      setExecuteListener(true);
                    }}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        )
        : (
          <div className="container-carousel">
            <Carousel countToShow={establishments.length}>
              {establishments?.map((establisment) => {
                return (
                  <CardDesktop
                    key={establisment._id}
                    title={establisment.nombre}
                    latitud={establisment.latitud}
                    longitud={establisment.longitud}
                    modules={establisment.modulos}
                    wifi={establisment.minUltimoValor}
                    idEstablishment={establisment._id}
                    handleBackListener={() => setExecuteListener(true)}
                    handleModuleTap={() => setExecuteListener(false)}
                  />
                );
              })}
            </Carousel>
          </div>
        )}
    </div>
  ) : <Spinner />;
};

export default withAuth(Home);
