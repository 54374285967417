import { DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, DatePicker, Radio, TimePicker } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createOrUpdateAutoThreshold, createOrUpdateAutoTimer } from '../../../redux/actions/serialListener';
import { api } from '../../../service/api';
import { CONTROL_PATH } from '../../../service/path';
import { convertToTimestamp, intervalosFormateados, isTimeRangeOverlapping } from '../../../utils/intervalTimeHelper';
import Icon from '../../icon';
export const PickerWithType = ({ type, onChange, onBlur, isValidRange, name, disabledTime, value }) => {
    const borderColor = isValidRange ? 'inherent' : '#FF6347'; // Cambiar el borde según la validez

    return (
        <>
            {type === 'time' ? (
                <TimePicker
                    disabledTime={disabledTime}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder="HH-MM"
                    format="HH:mm"
                    className="centered-timepicker"
                    inputReadOnly
                    name={name}
                    value={value}
                />
            ) : (
                <DatePicker
                    picker={type}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="borderless"
                    className="centered-timepicker"
                />
            )}
            <style>{`
                .centered-timepicker {
                    border: 2px solid ${borderColor} !important;
                }

                /* Cambiar color del placeholder según la validez */
                .centered-timepicker input::placeholder {
                    color: ${isValidRange ? '#666666' : '#FF6347'} !important;
                    font-weight: ${isValidRange ? 'normal' : 'bold'} !important;
                }
            `}</style>
        </>
    );
};

PickerWithType.propTypes = {
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func, // Agregado para validar onBlur
    isValidRange: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    disabledTime: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.instanceOf(dayjs), // Puede ser un objeto dayjs
        PropTypes.string, // O un string
        PropTypes.null // También puede ser null
    ])
};

const DevicesManager = ({
    title,
    onBack,
    deviceIcon,
    deviceStatus,
    deviceID,
    moduleID,
    serialID,
    maximumOptimunValue,
    isAuto,
    horarios,
    tipoAutomatizacion,
    tipoSensorId,
    unit
}) => {
    const dispatch = useDispatch();
    const [radioValue, setRadioValue] = useState(() => {
        switch (tipoAutomatizacion) {
            case 'umbral':
                return 'threshold';
            case 'horario':
                {
                    return 'time';
                }
            default:
                return null; // Para el caso 'manual' o cualquier otro valor
        }
    });

    const buttonLabels = ['ON', 'OFF', 'AUTO'];
    // Establecer el estado activo basado en isAuto
    const [activeButton, setActiveButton] = useState(isAuto ? 'AUTO' : deviceStatus);
    // ... existing state ...
    const [timeRanges, setTimeRanges] = useState(() => {
        if (horarios?.length > 0 && tipoAutomatizacion === 'horario' && isAuto) {
            return intervalosFormateados(horarios);
        }
        return [{ horaInicio: null, horaFin: null }];
    });
    const [isRangeValid, setIsRangeValid] = useState(true);
    const [isAvailable, setIsAvailable] = useState(false);
    const hasDispatchedThreshold = useRef(false);

    useEffect(() => {
        if (activeButton === 'AUTO' && radioValue === 'threshold' && !hasDispatchedThreshold.current) {
            try {
                const payload = {
                    serialId: serialID,
                    moduleId: moduleID,
                    idDispositivo: deviceID,
                    tipoSensor: tipoSensorId,
                    valorOptimo: maximumOptimunValue
                };
                if (tipoAutomatizacion !== 'umbral') {
                    dispatch(createOrUpdateAutoThreshold(payload));
                    hasDispatchedThreshold.current = true;
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }, [activeButton, radioValue]);

    useEffect(() => {
        if (activeButton !== 'AUTO') {
            hasDispatchedThreshold.current = false;
        }
    }, [activeButton]);

    const changeDeviceState = async (newState) => {
        const payload = {
            serialId: serialID,
            moduleId: moduleID,
            idDispositivo: deviceID,
            estado: newState
        };
        try {
            const response = await api.post(`${CONTROL_PATH}/serials-module-change`, payload);
            if (!response.data.success) {
                throw new Error(`Error al cambiar el estado del dispositivo: ${errorData.message || 'Error desconocido'}`);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleAddTimeRange = (e) => {
        e.preventDefault();

        setTimeRanges([
            ...timeRanges, {
                horaInicio: null, horaFin: null
            }
        ]);
    };
    function handleTimePicker(value, index, name) {
        const list = [...timeRanges];
        list[index][name] = value?.format('HH:mm:ss'); // Asegúrate de formatear el valor

        // Comprobar si ambos valores están completos
        if (list[index].horaInicio && list[index].horaFin) {
            const isOverlapping = isTimeRangeOverlapping(list[index], list.filter((_, idx) => idx !== index));
            if (isOverlapping) {
                setTimeRanges(list);
                setIsRangeValid(false);// Mensaje de advertencia si hay solapamiento
                setIsAvailable(false);
            } else {
                setIsRangeValid(true);
                setIsAvailable(true);
            }
        }

        setTimeRanges(list);
    }

    const handleBlur = () => {
        // Verificar que todos los rangos tengan horaInicio y horaFin
        const horarios = timeRanges.map(range => ({
            horaInicio: convertToTimestamp(range.horaInicio),
            horaFin: convertToTimestamp(range.horaFin)
        })).filter(range => range.horaInicio !== null && range.horaFin !== null); // Filtrar solo los rangos válidos
        // Verificar si hay horarios válidos y si el rango es válido
        if (horarios.length > 0 && isAvailable) {
            const payload = {
                serialId: serialID, // Reemplaza con el valor real
                moduleId: moduleID, // Reemplaza con el valor real
                idDispositivo: deviceID, // Reemplaza con el valor real
                horarios: horarios
            };

            // Enviar los datos al backend o procesarlos localmente
            dispatch(createOrUpdateAutoTimer(payload));
            // Aquí puedes realizar la llamada a la API para enviar el payload
        } else {
            console.error('Ambos valores deben estar completos antes de enviar o el rango no es válido.');
        }
    };

    const handleDelete = (time) => {
        const horarios = time.map(range => ({
            horaInicio: convertToTimestamp(range.horaInicio),
            horaFin: convertToTimestamp(range.horaFin)
        })).filter(range => range.horaInicio !== null && range.horaFin !== null); // Filtrar solo los rangos válidos
        // Verificar si hay horarios válidos y si el rango es válido
        if (horarios.length > 0) {
            const payload = {
                serialId: serialID, // Reemplaza con el valor real
                moduleId: moduleID, // Reemplaza con el valor real
                idDispositivo: deviceID, // Reemplaza con el valor real
                horarios: horarios
            };

            // Enviar los datos al backend o procesarlos localmente
            dispatch(createOrUpdateAutoTimer(payload));
            // Aquí puedes realizar la llamada a la API para enviar el payload
        } else {
            console.error('Ambos valores deben estar completos antes de enviar o el rango no es válido.');
        }
    };

    const handleButtonClick = (label) => {
        setActiveButton(label);
        setTimeRanges([{ horaInicio: null, horaFin: null }]);
        setIsRangeValid(true);
        if (label === 'ON') {
            changeDeviceState('on');
        } else if (label === 'OFF') {
            changeDeviceState('off');
        }
    };

    return (
        <div style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        }}>
            {/* Scrollable content including header */}
            <div style={{
                flex: 1,
                overflowY: 'auto',
                overflowX: 'hidden',
                minHeight: 0
            }}>
                {/* Header ahora dentro del área scrolleable */}
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Button
                            icon={<LeftOutlined />}
                            type="text" // Cambiado a 'text' para eliminar el borde
                            onClick={() => {
                                setTimeRanges([{ horaInicio: null, horaFin: null }]);
                                onBack();
                            }}
                            style={{ marginLeft: '16px' }} // Eliminar 'border: false' ya que no es necesario
                        />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center', // Cambiado a 'center' para centrar el contenid
                            marginBottom: '16px',
                            marginTop: '16px'
                        }}>
                            <div style={{ paddingRight: '4px' }}>
                                <Icon name={deviceIcon} style={{ marginRight: '16px' }} />
                            </div>
                            <h2 style={{
                                textAlign: 'center',
                            }}>
                                {title}
                            </h2>
                        </div>
                        <div style={{ marginLeft: '20px' }}></div>

                    </div >
                </div>

                {/* Resto del contenido */}
                <div>
                    <div style={{ textAlign: 'center', margin: '20px 0' }}>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        margin: '20px 0'
                    }}>
                        {buttonLabels.map((label) => {
                            const isActive = activeButton.toUpperCase() === label; // Corregido para usar toUpperCase()
                            return (
                                <Button
                                    key={label}
                                    disabled={false}
                                    onClick={() => handleButtonClick(label)}
                                    style={{
                                        backgroundColor: isActive ? 'rgba(0, 159, 203, 0.2)' : 'white', // Un gris más claro
                                        color: isActive ? '#000000' : '#b0b0b0', // Un gris más claro para el texto
                                        borderColor: isActive ? '#00A0A0' : '#d0d0d0', // Un gris más claro para el borde
                                        fontWeight: isActive ? 'bold' : 'normal',
                                        width: '25%',
                                        outline: isActive ? '2px solid #00A0A0' : 'none', // Foco visual
                                    }}
                                >
                                    {label}
                                </Button>
                            );
                        })}
                    </div>

                    {activeButton === 'AUTO' && (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '20px',
                            padding: '0 20px'
                        }}>
                            <Radio.Group
                                value={radioValue}
                                onChange={(e) => setRadioValue(e.target.value)}
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <style>{`
                                .ant-radio-wrapper {
                                    font-weight: bold !important;
                                }
                                .ant-radio-checked .ant-radio-inner {
                                    border-color: #00A0A0 !important;
                                    background-color: rgba(0, 159, 203, 0.2) !important;
                                }
                                .ant-radio-checked .ant-radio-inner::after {
                                     background-color: rgba(0, 159, 203, 0.1)
                                }
                                .ant-radio:hover .ant-radio-inner {
                                    border-color: #00A0A0 !important;
                                }
                                .centered-timepicker .ant-picker-input > input {
                                    text-align: center;
                                    color: #666666;
                                }
                                .centered-timepicker .ant-picker-input > input[value] {
                                    color: #000000 !important;
                                    font-weight: 500;
                                }
                            `}</style>
                                <Radio value="time">Por tiempo</Radio>
                                {maximumOptimunValue && <Radio value="threshold">Por umbral</Radio>}
                            </Radio.Group>
                        </div>
                    )}

                    {activeButton === 'AUTO' && radioValue === 'time' && (
                        <>
                            {timeRanges.map((range, index) => (
                                <div key={range.id}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginBottom: '15px',
                                            gap: '8px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                gap: '8px',
                                                width: '100%',
                                            }}
                                        >
                                            <h2
                                                style={{
                                                    margin: 0,
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                    color: '#333',
                                                }}
                                            >
                                                INICIO
                                            </h2>
                                            <PickerWithType
                                                type="time"
                                                onChange={(value) => handleTimePicker(value, index, 'horaInicio')}
                                                isValidRange={isRangeValid}
                                                name='horaInicio'
                                                value={dayjs(timeRanges[index]?.horaInicio, 'HH:mm:ss').isValid() ? dayjs(timeRanges[index]?.horaInicio, 'HH:mm:ss') : null}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                gap: '8px',
                                                width: '100%',
                                            }}
                                        >
                                            <h2
                                                style={{
                                                    margin: 0,
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                    color: '#333',
                                                }}
                                            >
                                                FIN
                                            </h2>
                                            <PickerWithType
                                                type="time"
                                                onChange={(value) => handleTimePicker(value, index, 'horaFin')}
                                                isValidRange={isRangeValid}
                                                name='horaFin'
                                                onBlur={handleBlur}
                                                value={dayjs(timeRanges[index]?.horaFin, 'HH:mm:ss').isValid() ? dayjs(timeRanges[index]?.horaFin, 'HH:mm:ss') : null}
                                            />
                                        </div>
                                        {timeRanges.length > 1 && (
                                            <Button
                                                type="text"
                                                icon={<DeleteOutlined style={{ color: '#666666' }} />}
                                                onClick={() => {
                                                    const newRanges = timeRanges.filter((_, i) => i !== index);
                                                    setTimeRanges(newRanges);
                                                    // Verificar si hay solapamiento en los nuevos rangos después de eliminar
                                                    const hasOverlap = newRanges.some((range, i) =>
                                                        isTimeRangeOverlapping(range, newRanges.filter((_, idx) => idx !== i))
                                                    );

                                                    if (hasOverlap) {
                                                        setIsRangeValid(false); // Marcar como no válido si hay solapamiento
                                                        return; // No actualices el estado si hay solapamiento
                                                    }

                                                    // Si no hay solapamiento
                                                    setIsRangeValid(true); // Validar el rango si no hay solapamiento
                                                    setIsAvailable(true);
                                                    handleDelete(newRanges);
                                                }}
                                                style={{
                                                    marginTop: '25px',
                                                    padding: 0,
                                                    minWidth: '40px',
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                            {!isRangeValid && (
                                <p>El rango ingresado no es válido. Asegúrese de que el tiempo de inicio sea anterior al de fin y que no se superponga con otros rangos.</p>
                            )}
                            <a
                                href="#"
                                onClick={handleAddTimeRange}
                                style={{
                                    color: '#009FCB',
                                    textDecoration: 'none',
                                    display: 'block',
                                    textAlign: 'center',
                                    marginTop: '10px',
                                    fontSize: '14px'
                                }}
                            >
                                + Agregar nuevo rango de tiempo
                            </a>
                            {timeRanges.length > 0 && isRangeValid && (
                                <p style={{
                                    marginTop: '18px',
                                    fontSize: '14px',
                                    color: '#000',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                }}>
                                    El {title} se encenderá
                                    {timeRanges.map((range, index) => {
                                        if (range.horaInicio && range.horaFin) {
                                            const inicio = dayjs(range.horaInicio, 'HH:mm:ss').format('h:mm a');
                                            const fin = dayjs(range.horaFin, 'HH:mm:ss').format('h:mm a');
                                            return (
                                                <span key={index}>
                                                    {index > 0 && index === timeRanges.length - 1 ? ' y ' : index > 0 ? ', ' : ' '}
                                                    de {inicio} a {fin}
                                                </span>
                                            );
                                        }
                                        return null;
                                    })}
                                </p>
                            )}
                        </>
                    )}
                    {activeButton === 'AUTO' && radioValue === 'threshold' && (
                        <div style={{
                            margin: '20px',
                            padding: '0 20px'
                        }}>
                            <h3 style={{
                                margin: 0,
                                fontSize: '16px',
                                fontWeight: 'normal'
                            }}>
                                Valor Optimo maximo de perfil
                            </h3>
                            <div
                                style={{
                                    display: 'inline-block',
                                    padding: '8px 16px',
                                    border: '2px solid #00bfa5',
                                    borderRadius: '8px',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    color: '#000',
                                    backgroundColor: 'transparent',
                                    userSelect: 'none',
                                    cursor: 'default',
                                    marginTop: '8px'
                                }}
                            >
                                {maximumOptimunValue} {unit.nombre}
                            </div>

                            {maximumOptimunValue && (
                                <p style={{
                                    marginTop: '18px',
                                    fontSize: '14px',
                                    color: '#000',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                }}>
                                    El {title} se encenderá cuando  supere los {parseFloat(maximumOptimunValue + 0.5)} {unit.nombre}  y se apagará cuando baje a {parseFloat(maximumOptimunValue - 0.5)} {unit.nombre}.
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
DevicesManager.propTypes = {
    title: PropTypes.string.isRequired,
    onBack: PropTypes.func.isRequired,
    deviceIcon: PropTypes.string.isRequired,
    deviceStatus: PropTypes.object.isRequired,
    deviceID: PropTypes.string.isRequired,
    moduleID: PropTypes.string.isRequired,
    serialID: PropTypes.string.isRequired,
    maximumOptimunValue: PropTypes.string.isRequired,
    isAuto: PropTypes.bool.isRequired,
    horarios: PropTypes.array.isRequired,
    tipoAutomatizacion: PropTypes.string.isRequired,
    tipoSensorId: PropTypes.string.isRequired,
    unit: PropTypes.string,
};

export default DevicesManager;
