/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { LeftOutlined, SettingOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { createBinnacle, getAllBinnacleTypes } from '../../../redux/actions/binnacles';
import {
  cleanCropProfile, getAllCropProfilesModulo, getCropProfileByModuleId
} from '../../../redux/actions/crop-profile';
import { getAllEstablishmentsPanelNotLoading } from '../../../redux/actions/home';
import { updateModuleById } from '../../../redux/actions/module';
import { REPORTS_PATH } from '../../../routes/path';
import { api } from '../../../service/api';
import { CONTROL_PATH, CROP_PROFILE_API_PATH } from '../../../service/path';
import { deviceSelectionIcon } from '../../../utils/deviceSelectionIcon';
import { DeviceStatusColors } from '../../../utils/DevicesStatusColors';
import { findUnit } from '../../../utils/findUnit';
import { getOptimusValuesBysensor } from '../../../utils/getOptimusValuesBySensor';
import Button from '../../button';
import CardColor from '../../card-color';
import CarouselCard from '../../carrusel/card-carousel';
import Icon from '../../icon';
import SpinnerInto from '../../spinner-inner-components';
import ChangeCropProfile from '../change-crop';
import DevicesManager from '../device-manager';
import FormAcontecimientos from '../form-acontecimiento';
import FormCosecha from '../form-cosecha';
import InfoParameter from '../info-parameters';
import InfoSensor from '../info-sensor';
const Modulos = ({
  modules, wifi, idEstablishment, handleBackListener, handleModuleTap
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [numberModule, setNumberModule] = useState();
  // const [cropprofile, setCropprofile] = useState();
  const [showSensors, setShowSensors] = useState(false);
  const [showInformacion, setShowInformacion] = useState(false);
  const [infoSensor, setInfoSensor] = useState();
  const [sensorParameters, setSensorParameters] = useState([]);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [moduleId, setModuleId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenBinnacle, setIsModalOpenBinnacle] = useState(false);
  const [isModalOpenCloseCrop, setIsModalOpenCloseCrop] = useState(false);
  const [isModalOpenRecomendacion, setIsModalOpenRecomendacion] = useState(false);
  const [isModalOpenAlerta, setIsModalOpenAlerta] = useState(false);
  const [cropprofile, setCropprofile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDevice, setIsLoadingDevice] = useState(false);
  const [idCrop, setIdCrop] = useState();
  const [colorConnection, setColorConnection] = useState();
  const [showNewView, setShowNewView] = useState(false);
  const [showDeviceManager, setShowDeviceManager] = useState(false);
  const [selectedDeviceType, setSelectedDeviceType] = useState(null);
  const [devices, setDevices] = useState([]);
  const [executeListener, setExecuteListener] = useState(true);
  const [horarios, setHorarios] = useState([]); // Estado para almacenar todos los horarios);
  const [activeSchedules, setActiveSchedules] = useState(false);
  const [lastMinute, setLastMinute] = useState(null);

  const { cropprofiles } = useSelector((state) => state.cropprofileReducer);
  const { binnacles } = useSelector((state) => state.binnacleReducer);
  const colorClass = () => {
    if (wifi === 'rojo') setColorConnection('ConnectionRed');
    else if (wifi === 'amarillo') setColorConnection('ConnectionYellow');
    else if (wifi === 'verde') setColorConnection('ConnectionGreen');
    else setColorConnection('Connection');
  };
  const getColorCard = (id) => {
    const modulesSensors = modules?.map(({ _id, perfilcultivo }) => {
      if (perfilcultivo) {
        return perfilcultivo?.parametros?.map(
          ({ sensor }) => ({ modulo: _id, sensor })
        );
      }
      return _id;
    });

    const color = modulesSensors
      .flat(2)
      .filter(({ modulo }) => modulo === id)
      .map(({ sensor }) => {
        if (sensor.estado === 'Critico bajo' || sensor.estado === 'Critico alto') return 'red';
        if (sensor.estado === 'No óptimo bajo' || sensor.estado === 'No óptimo alto') return 'yellow';
        return 'green';
      });
    if (color.includes('red')) return 'red';
    if (color.includes('yellow')) return 'yellow';
    return 'green';
  };

  /* Redirecciona a reportes */
  const viewReports = () => {
    navigate(`${REPORTS_PATH}/${idEstablishment}`);
  };

  const viewParameters = () => {
    setShowSensors(true);
  };

  /* Funcion que muestra un perfil de cultivo */
  const showInfoCropProfile = (id, number, idModule) => {
    setNumberModule(number);
    if (id === undefined) {
      setCropprofile(null);
    } else dispatch(getCropProfileByModuleId(id, idModule));
    viewParameters();
    setModuleId(idModule);
    setIdCrop(id);
  };

  /* Navegacion back a los modulos */
  const hiddenParameters = () => {
    setShowSensors(false);
    dispatch(cleanCropProfile());
    handleBackListener();
  };

  const hiddenParametersBack = () => {
    setShowSensors(false);
    dispatch(cleanCropProfile());
    dispatch(getAllEstablishmentsPanelNotLoading());
  };

  /* Mostrar la informacion de sensor */
  const viewSensor = () => {
    setShowInformacion(true);
  };

  // useEffect para obtener todos los parámetros de los sensores
  useEffect(() => {
    if (cropprofile && cropprofile.parametros) {
      setSensorParameters(cropprofile.parametros);
    }
  }, [cropprofile]);

  const showInfoSensor = (id) => {
    const sensorParameter = cropprofile.parametros?.find(({ _id }) => _id === id);
    setInfoSensor({ cropprofile: cropprofile.nombre, ...sensorParameter });
    viewSensor();
  };

  /* Navegacion back a los parametros */
  const hiddenSensor = () => {
    setShowInformacion(false);
  };

  // #region modales

  /* Botones en footer en parametros */
  const showModalCroppoint = () => {
    setIsModalOpen(true);
    dispatch(getAllCropProfilesModulo(idEstablishment));
  };
  const showModalBinnacle = () => {
    setIsModalOpenBinnacle(true);
    dispatch(getAllBinnacleTypes());
  };
  const showModalCloseCrop = () => {
    setIsModalOpenCloseCrop(true);
  };
  const showModalRecomendacion = () => {
    setIsModalOpenRecomendacion(true);
  };
  const showModalAlerta = () => {
    setIsModalOpenAlerta(true);
  };

  const iconButtons = [
    {
      id: 'CambiodePerfil', func: showModalCroppoint, disabled: false
    },
    {
      id: 'Cosecha', func: showModalBinnacle, disabled: !cropprofile?.campaña
    },
    {
      id: 'IngresoManual', func: showModalCloseCrop, disabled: !cropprofile?.campaña
    },
    {
      id: 'Alerta', func: showModalAlerta, disabled: false
    },
    {
      id: 'Recomendacion', func: showModalRecomendacion, disabled: false
    },
  ];

  const iconButtonsNotSuperPerfil = [
    {
      id: 'CambiodePerfil', func: showModalCroppoint, disabled: false
    },
    {
      id: 'Cosecha', func: showModalBinnacle, disabled: !cropprofile?.campaña
    },
    {
      id: 'IngresoManual', func: showModalCloseCrop, disabled: !cropprofile?.campaña
    },

  ];

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCloseModalChangeCrop = () => {
    setIsModalOpen(false);
    hiddenParametersBack();
  };

  const handleCancelBinnacle = () => {
    setIsModalOpenBinnacle(false);
  };

  const handleCancelCrop = () => {
    setIsModalOpenCloseCrop(false);
  };

  const handleCloseCrop = () => {
    setIsModalOpenCloseCrop(false);
    hiddenParametersBack();
  };

  const handleCancelRecomendacion = () => {
    setIsModalOpenRecomendacion(false);
  };

  const handleCancelAlerta = () => {
    setIsModalOpenAlerta(false);
  };

  const handleChangeModule = (id, idperfilcultivo) => {
    const newObject = { perfilcultivo: idperfilcultivo };
    dispatch(updateModuleById(id, newObject, handleCloseModalChangeCrop));
  };
  const handleBinnacleOk = (newAcontecimiento) => {
    dispatch(createBinnacle(newAcontecimiento, handleCancelBinnacle));
  };

  const handleCloseCropOk = (values) => {
    dispatch(createBinnacle({ ...values, campaña: cropprofile?.campaña?._id }, handleCloseCrop));
  };
  // #endregion

  /*  */
  useEffect(() => {
    let intervalIdGet;
    let intervalIdPost;
    // Agregar showNewView a la condición
    if (moduleId && !showDeviceManager) {
      fetchDevices();
    }

    // Mantener el resto del useEffect igual...
    if (showSensors && idCrop && executeListener) {
      setIsLoading(true);

      // Primera llamada GET para el perfil de cultivo
      api.get(`${CROP_PROFILE_API_PATH}/${idCrop}/${moduleId}`)
        .then((res) => {
          setCropprofile(res.data);
          setIsLoading(false);
          colorClass();
        });
      intervalIdGet = setInterval(() => {
        api.get(`${CROP_PROFILE_API_PATH}/${idCrop}/${moduleId}`)
          .then((res) => {
            setCropprofile(res.data);
            //setThresholdParams(res.data.parametros);
          });
      }, 10000);
    } else if (!showSensors) {
      clearInterval(intervalIdGet);
      setCropprofile(null);
    };
    return () => {
      clearInterval(intervalIdGet);
      clearInterval(intervalIdPost);
      // Solo limpiar cropprofile si no estamos en la vista nueva
      if (!showNewView) {
        setCropprofile(null);
      }
    };
  }, [idCrop, showSensors, showNewView, moduleId, executeListener, activeSchedules, showDeviceManager]);

  const fetchDevices = () => {
    if (!moduleId) return;

    const currentModule = modules?.find(module => module._id === moduleId);
    const serialsList = currentModule?.seriales?.map(serial => serial.serial._id) || [];

    setIsLoadingDevice(true);

    api.post(`${CONTROL_PATH}/serials-module`, {
      serials: serialsList,
      moduleId: moduleId
    })
      .then((res) => {
        if (res.data && res.data.results) {
          const allPositions = res.data.results.flatMap(result =>
            result.posicionesOcupadas.map(posicion => ({
              ...posicion,
              serialId: result.serialId
            }))
          );
          setDevices(allPositions);
          const horariosFromPositions = allPositions.flatMap(dispositivo => dispositivo.horarios || []);
          setHorarios(horariosFromPositions);
          setActiveSchedules(false);
        }
      })
      .finally(() => setIsLoadingDevice(false))
      .catch((error) => {
        console.log(error);
        setDevices([]);
      });
  };

  const updateCountdown = () => {
    const currentTime = new Date(); // Hora actual
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    // Evitar múltiples llamados en el mismo minuto
    if (currentMinute === lastMinute) {
      return;
    }
    setLastMinute(currentMinute);
    horarios.filter((horario) => {
      // Convertir timestamps a objetos Date
      const horarioStart = new Date(horario.horaInicio * 1000);
      const horarioEnd = new Date(horario.horaFin * 1000);

      const startHour = horarioStart.getHours();
      const startMinute = horarioStart.getMinutes();
      const endHour = horarioEnd.getHours();
      const endMinute = horarioEnd.getMinutes();

      // Verificar coincidencia exacta con inicio o fin
      const isStartMatch = currentHour === startHour && currentMinute === startMinute;
      const isEndMatch = currentHour === endHour && currentMinute === endMinute;
      // Mostrar coincidencias
      if (isStartMatch) {
        setActiveSchedules(true);
      }
      if (isEndMatch) { setActiveSchedules(true); }
      return isStartMatch || isEndMatch;
    });
  };

  useEffect(() => {
    let interval = null;
    if (!showDeviceManager) {
      updateCountdown();

      interval = setInterval(updateCountdown, 10000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [horarios, lastMinute, showDeviceManager]);

  useEffect(() => { colorClass(); }, [wifi]);
  const handleNewViewClick = () => {
    setShowNewView(!showNewView);
    if (showSensors) setShowSensors(false);
    if (showInformacion) setShowInformacion(false);
  };

  const handleUpdateContent = () => {
    fetchDevices();
  };

  const handleBackToModules = () => {
    setShowNewView(false);
    if (showSensors) setShowSensors(false);
    if (showInformacion) setShowInformacion(false);
    handleBackListener();
  };

  const handleBackFromDevice = () => {
    setExecuteListener(true);
    setShowDeviceManager(false);
    setSelectedDeviceType(null);
    handleBackListener();
  };

  const handleDeviceClick = (device) => {
    if (handleModuleTap) {
      handleModuleTap();
    }
    setExecuteListener(false);
    const unit = findUnit(sensorParameters, device.typeSensorDevice);
    const optimusValues = getOptimusValuesBysensor(device.type, sensorParameters);
    setSelectedDeviceType({
      title: device.name,
      icon: deviceSelectionIcon(device.iconType),
      color: device.color,
      status: device.status,
      deviceID: device.id,
      serialID: device.serialID,
      maximumOptimunValue: optimusValues?.maximo,
      tipoSensorId: optimusValues?.tipoSensorId,
      isAuto: device.isAuto,
      type: device.type,
      horarios: device.horarios,
      tipoAutomatizacion: device.tipoAutomatizacion,
      unit: unit?.unit
    });
    setShowDeviceManager(true);
  };

  return (
    <>
      {modules?.length !== 0 ? (
        <>
          <div className="card-body">
            {!showSensors && !showNewView ? (
              <div className="modulos">
                <CarouselCard styles="slick-grid" countToShow={modules.length}>
                  {modules?.map(({ numero, perfilcultivo, _id }) => (
                    <CardColor
                      key={_id}
                      value={perfilcultivo ? numero : numero}
                      color={perfilcultivo ? getColorCard(_id) : 'grey'}
                      onClick={() => showInfoCropProfile(perfilcultivo?._id, numero, _id)}
                    />
                  ))}
                </CarouselCard>
              </div>
            ) : showSensors ? (
              <>
                <div className="parametros">
                  {!showInformacion ? (
                    <>
                      <InfoParameter
                        title={numberModule}
                        idModule={moduleId}
                        onHiddenParameters={hiddenParameters}
                        onViewInfoSensor={showInfoSensor}
                        cropprofile={cropprofile}
                        isLoading={isLoading}
                        open={isModalOpenAlerta}
                        cancel={handleCancelAlerta}
                      />
                    </>
                  ) : (
                    <InfoSensor data={infoSensor} onHiddenSensor={hiddenSensor} />
                  )}
                </div>
              </>
            ) : showDeviceManager ? (
              <div style={{
                height: '100%',
                position: 'relative'
              }}>

                <DevicesManager
                  title={selectedDeviceType?.title}
                  deviceIcon={selectedDeviceType?.icon}
                  deviceColor={selectedDeviceType?.color}
                  onBack={handleBackFromDevice}
                  deviceStatus={selectedDeviceType?.status}
                  deviceID={selectedDeviceType?.deviceID}
                  moduleID={moduleId}
                  serialID={selectedDeviceType?.serialID}
                  maximumOptimunValue={selectedDeviceType?.maximumOptimunValue}
                  isAuto={selectedDeviceType?.isAuto}
                  horarios={selectedDeviceType?.horarios}
                  tipoAutomatizacion={selectedDeviceType?.tipoAutomatizacion}
                  tipoSensorId={selectedDeviceType?.tipoSensorId}
                  unit={selectedDeviceType?.unit}
                />
              </div>
            ) : (
              <>
                {isLoadingDevice ? (
                  <SpinnerInto />
                ) : (
                  <div className='parametros'>
                    <div className="card-info-home__button-nav">
                      <Button icon={<LeftOutlined />} color="black" onClick={handleBackToModules} />
                    </div>
                    <div className="card-info-home__parametersHeigth">
                      <p className="card-info-home__subtitle">Control de sala</p>
                      <div className="card-info-home__sensor-mobile">
                        {devices && devices.length > 0 ? (
                          <CarouselCard styles="slick-grid">
                            {devices.map((device) => (
                              <CardColor
                                key={device.idDispositivo}
                                icon={deviceSelectionIcon(device.deviceType.name)}
                                color={DeviceStatusColors[device.estado.toLowerCase()]}
                                onClick={
                                  device.estado.toLowerCase() !== 'disabled'
                                    ? () => handleDeviceClick({
                                      name: device.nombreDispositivo,
                                      type: device.deviceType.sensorType.nombre,
                                      status: device.estado,
                                      id: device.idDispositivo,
                                      position: device.posicion,
                                      serialID: device.serialId,
                                      isAuto: device.modoAuto,
                                      horarios: device.horarios,
                                      tipoAutomatizacion: device.tipoAutomatizacion,
                                      typeSensorDevice: device.deviceType.sensorType,
                                      iconType: device.deviceType.name,
                                    })
                                    : undefined
                                }
                                disabled={device.estado.toLowerCase() === 'disabled'}
                              />
                            ))}
                          </CarouselCard>
                        ) : (
                          <p className="messege-not-content">Sin dispositivos conectados</p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div className={isMobile ? 'card-footer' : 'card-home-desktop__footer'}>
            <div style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              maxWidth: '100%',
            }}>
              <div />

              <div style={{
                display: 'flex',
                justifyContent: 'center',
                justifyItems: 'center',
                gap: '8px',
                width: showSensors ? '33%' : 'auto'
              }}>
                <Button
                  color="transparent"
                  icon={<Icon name={colorConnection} />}
                />

                <Button
                  type="primary"
                  onClick={viewReports}
                  htmlType="button"
                  color="transparent"
                  icon={<Icon name="Reporte" />}
                />

                <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
                  {cropprofile?.superPerfil === 'Si'
                    ? iconButtons.map(({
                      id, func, disabled
                    }) => (
                      <Button
                        key={id}
                        color="transparent"
                        disabled={disabled}
                        onClick={() => func()}
                        icon={<Icon name={id} />}
                      />
                    ))
                    : iconButtonsNotSuperPerfil.map(({
                      id, func, disabled
                    }) => (
                      <Button
                        key={id}
                        color="transparent"
                        disabled={disabled}
                        onClick={() => func()}
                        icon={<Icon name={id} />}
                      />
                    ))}
                </div>
              {showSensors && (
                <Button
                  type="primary"
                  onClick={handleNewViewClick}
                  htmlType="button"
                  color="transparent"
                  icon={<SettingOutlined style={{ color: 'white', fontSize: isMobile? '20px' : '20px' }} />}
                  style={{
                    marginInline: isMobile ? '8px' : '16px',
                    marginTop: isMobile && '-3px'
                  }}
                />
              )}
              </div>

              {devices && devices.length > 0 && !showSensors && showNewView && (
                <div style={
                  { justifyItems: 'center', paddingTop: '4px', paddingLeft: '6px ' }

                }>
                  <Button
                    type="primary"
                    onClick={handleUpdateContent}
                    htmlType="button"
                    color="transparent"
                    icon={<Icon name={'Refresh'} size={28} />}
                  />

                </div>
              )
              }
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="card-body">
            <div className="modulos">
              <p className="messege-not-content">Sin modulos asignados</p>
            </div>
          </div>
          <div className={isMobile ? 'card-footer' : 'card-home-desktop__footer'}>
            <Button
              color="transparent"
              icon={<Icon name={colorConnection} />}
            />
            <Button
              type="primary"
              onClick={viewReports}
              htmlType="button"
              color="transparent"
              icon={<Icon name="Reporte" />}
            />

          </div>
        </>
      )}
      <Modal
        title="Perfiles de cultivo"
        open={isModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
      >
        <ChangeCropProfile
          cropprofiles={cropprofiles}
          idModule={moduleId}
          onChange={handleChangeModule}
          cropprofile={cropprofile}
        />
      </Modal>
      <Modal
        title="Agregar acontecimiento"
        open={isModalOpenBinnacle}
        onOk={handleCancelBinnacle}
        onCancel={handleCancelBinnacle}
        footer={null}
      >
        <FormAcontecimientos
          onSubmit={handleBinnacleOk}
          cropprofile={cropprofile}
          binnacles={binnacles}
        />
      </Modal>
      <Modal
        title="Cierre de cosecha"
        open={isModalOpenCloseCrop}
        onOk={handleCloseCropOk}
        onCancel={handleCancelCrop}
        footer={null}
      >
        <FormCosecha
          onSubmit={handleCloseCropOk}
          cropprofile={cropprofile}
        />
      </Modal>
      <Modal
        title="Recomendacion"
        open={isModalOpenRecomendacion}
        onOk={handleCancelRecomendacion}
        onCancel={handleCancelRecomendacion}
        footer={null}
        className="modal-celphone"
      >
        <p>
          {cropprofile?.recomendacion ? cropprofile?.recomendacion : 'No se encontraron recomendaciones.'}
        </p>
      </Modal>
      <Modal
        title="Alerta"
        open={isModalOpenAlerta}
        onOk={handleCancelAlerta}
        onCancel={handleCancelAlerta}
        footer={null}
        className="modal-celphone"
      >
        <p>{cropprofile?.alerta ? cropprofile?.alerta : 'No se encontraron alertas.'}</p>
      </Modal>
    </>
  );
};
Modulos.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape()),
  wifi: PropTypes.string,
  idEstablishment: PropTypes.string,
  handleBackListener: PropTypes.func,
  handleModuleTap: PropTypes.func
};
Modulos.defaultProps = {
  modules: [],
  wifi: '',
  idEstablishment: '',
  handleBackListener: () => { },
  handleModuleTap: () => { }
};
export default Modulos;

/*
Umbral con 60
valor actual es 62 y el umbral es 60 y esta en estado off
valor actual es 58 y el umbral es 60 y esta en estado on
*/